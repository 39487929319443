import moment from "moment";

export const FieldValidator = (
  validationType,
  value,
  type,
  validate,
  validate2,
  noOfMonths,
  validateJoiningDate
) => {
  function dragDropFileValidationFileType(fileValues) {
    // console.log("fileValues: <<<<<<<<<<< ", fileValues);

    for (let i = 0; i < fileValues.length; i++) {
      let val =  fileValues[i]?.type.split("/")[1];
      // console.log("chack val",val);
      if (
       val !== "pdf" && val !== "PDF" &&
        val !== "png" && val !== "PNG" &&
        val !== "jpg" && val !== "JPG" &&
        val !== "jpeg" && val !== "JPEG" &&
        val !== "xls" &&  val !== "XLS"  &&
       val !== "vnd.ms-excel"  && val !== "VND.MS-EXCEL" &&
       val !==
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        
        return true;
      }
    }
    return false;
  }

  function dragDropFileValidationUniqueFiles(fileValues) {
    const uniqueValues = new Set(fileValues?.map((v) => v.name));

    if (uniqueValues.size < fileValues.length) {
      //console.log("duplicates found");
      return true;
    } else return false;
  }
  function editDragDropFileValidationUniqueFiles(fileTypes, fileValues) {
    if (fileValues?.length != 0 && fileTypes?.length != 0) {
      let uniqueValues1 = new Set(fileValues?.map((v) => v.name));
      let uniqueValues2 = new Set(fileTypes?.map((v) => v.FileName));
      const haveCommonValue = [...uniqueValues1].some((value) =>
        uniqueValues2.has(value)
      );

      //console.log("fileValues duplicates", fileValues, "fileTypes", fileTypes);
      if (
        uniqueValues1.size < fileValues?.length ||
        uniqueValues2.size < fileTypes?.length ||
        haveCommonValue == true
      ) {
        //console.log("duplicates found1");
        return true;
      } else return false;
    } else if (fileValues?.length == 0 && fileTypes?.length != 0) {
      let uniqueValues2 = new Set(fileTypes?.map((v) => v.FileName));
      if (uniqueValues2.size < fileTypes?.length) {
        //console.log("duplicates found2");
        return true;
      } else return false;
    } else if (fileValues?.length != 0 && fileTypes?.length == 0) {
      let uniqueValues1 = new Set(fileValues?.map((v) => v.name));
      if (uniqueValues1.size < fileTypes?.length) {
        //console.log("duplicates found3");
        return true;
      } else return false;
    }
  }
  function dragDropFileValidationTotalSize(fileValues) {
    if (
      fileValues?.reduce((acc, curr) => (acc = acc + Number(curr.size)), 0) >
      10485760
    ) {
      //console.log("size>10mb");
      return true;
    } else return false;
  }
  function editDragDropFileValidationTotalSize(fileTypes, fileValues) {
    if (
      fileTypes?.reduce(
        (acc, curr) => (acc = acc + Number(curr.attachmentsize)),
        0
      ) +
        fileValues?.reduce((acc, curr) => (acc = acc + Number(curr.size)), 0) >
      10485760
    ) {
      //console.log("size>10mb");
      return true;
    } else return false;
  }
  function uploadFileNameSpecialCharacterValidation(fileValues) {
    let tempFileList = [];
    for (let i = 0; i < fileValues.length; i++) {
      tempFileList.push({ fileName: fileValues[i].name });
    }
    if (typeof tempFileList !== "undefined" && tempFileList.length > 0) {
      var newArrayVal = [];

      for (var i = 0; i <= tempFileList.length; i++) {
        var fileCheck =
          tempFileList && tempFileList[i] !== undefined
            ? tempFileList[i].fileName
            : "";
        if (fileCheck?.substr(fileCheck?.length - 4) == (".png" || ".jpg" || ".PNG" || ".JPG")) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 4));
        } else if (
          fileCheck?.substr(fileCheck?.length - 5) == ".jpeg" ||
          ".xlsx" || ".JPEG" || ".XLSX"
        ) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 5));
        }
      }
      var format = /[`!@#$%^&*+\=\[\]{};':"\\|,.<>\/?~]/;
      var lname = newArrayVal?.map((obj) => {
        return format.test(obj);
      });
      if (lname && lname.includes(true)) {
        return true;
      } else return false;
    }
  }

  function uploadFileNameCharLengthValidation(fileValues) {
    //console.log("lkjdfs1", fileValues);
    let tempFileList = [];
    for (let i = 0; i < fileValues.length; i++) {
      tempFileList.push({ fileName: fileValues[i].name });
    }
    if (typeof tempFileList !== "undefined" && tempFileList.length > 0) {
      //console.log("lkjdfs2", tempFileList);
      var newArrayVal = [];
      for (var i = 0; i <= tempFileList.length; i++) {
        var fileCheck =
          tempFileList && tempFileList[i] !== undefined
            ? tempFileList[i].fileName
            : "";
        //console.log("lkjdfs3", fileCheck);
        if (fileCheck?.substr(fileCheck?.length - 4) == (".png" || ".jpg" || ".PNG" || ".JPG")) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 4));
        } else if (
          fileCheck?.substr(fileCheck?.length - 5) == ".jpeg" ||
          ".xlsx" || ".JPEG" || ".XLSX"
        ) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 5));
        }
      }

      for (var i = 0; i <= newArrayVal.length; i++) {
        if (newArrayVal[i]?.length > 100) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  let data = {};
  let list = null;
  const handleAttendees = (value, type, validate) => {
    //console.log(" value--- in handleAttendees: ", value);
    //console.log("type--- in handleAttendees: ", type);
    //console.log("validate--- in handleAttendees: ", validate);
    const perHead = Number(type / value.length);
    //console.log("value of perhead--- in handleAttendees: ", perHead);

    let newArray = validate?.filter((array22) =>
      value.some((array11) => array22.empId === array11.empId)
    );
    //console.log("new Array--- in handleAttendees: ", newArray);

    list = newArray?.filter((check) => perHead > Number(check.remainingAmount));
    //console.log("new Array--- in handleAttendees2: ", validate);
    //console.log("list--- in handleAttendees: ", list);

    return list;
  };
  const projectAndSplitBoxValidation = (projectsList, claimAmt) => {
    // console.log(" value: in projectAndSplitBoxValidation:      ", projectsList);

    // console.log(" type:  in projectAndSplitBoxValidation:   ", claimAmt);
    let totalAmt = projectsList.reduce((acc, curr) => {
      return (acc = acc + Number(curr.splitAmount));
    }, 0);
    //console.log("totalAmt", totalAmt);
    if (totalAmt.toFixed(2) != claimAmt) {
      return true;
    } else return false;
  };
  function negativeValidation(fileValues) {
    if (Number(fileValues) < 0) {
      return true;
    } else if (
      Array.isArray(fileValues) &&
      fileValues.findIndex((item) => Number(item.splitAmount) < 0) !== -1
    ) {
      return true;
    } else return false;
  }

  switch (validationType) {
    case "purpose":
      if (value.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please select the Purpose",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
    case "expense Category":
      if (value.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please select the Expense Category",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "Vendor Name":
      if (value.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter the Vendor Name",
        };
      } else if (value.length > 100) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter the smaller Vendor Name",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "Bill Number":
      if (value.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter the Bill Number",
        };
      } else if (value.length > 50) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter the smaller Bill Number",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "GST":
      if (value.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter the GST",
        };
      } else if (value.length > 50) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter the smaller GST Number",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "expense Name":
      if (value.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter the Expense Name",
        };
      } else if (value.length > 100) {
        data = {
          type: validationType,
          bool: false,
          message:
            "*Please enter an Expense Name with the limit of 100 characters",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "Reciept Date":
      // //console.log(
      //   "empJoiningDate in validation.js12",
      //   moment(noOfMonths?.empJoiningDate).toDate(),
      //   "value",
      //   moment(value).toDate(),
      //   "tttttteeee",
      //   validateJoiningDate
      // );
      // //console.log(
      //   "empJoiningDate 123456",
      //   moment(value).toDate() < moment(noOfMonths?.empJoiningDate).toDate()
      // );
      if (value === null) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please select the Date",
        };
      } else if (type) {
        //console.log("ty1pe", type);
        data = {
          type: validationType,
          bool: false,
          message: "Days limit is expired for this Category ",
        };
      } else if (validate) {
        //console.log("ty1pe34", validate);
        data = {
          type: validationType,
          bool: false,
          message: `Last date to submit the expense was ${moment(
            validate2.setDate(validate2.getDate() - 1)
          ).format("l")}`,
        };
      } else if (validateJoiningDate) {
        // //console.log(
        //   "tttttteeee empJoiningDate in validation.js",
        //   moment(value).toDate() < moment(noOfMonths?.empJoiningDate).toDate()
        // );
        data = {
          type: validationType,
          bool: false,
          message: `Reciept date cannot be less than Joining date.`,
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
    // case "Dependents":
    //   if (value.length === 0) {
    //     data = {
    //       type: validationType,
    //       bool: false,
    //       message: "*Please choose Relationship",
    //     };
    //   } else {
    //     data = { type: validationType, bool: true, message: "" };
    //   }
    //   break;
    case "Drag Drop File":
      //console.log(" type?.length editValidation02 ", type);
      //console.log(" value.length editValidation03", value, validate);
      if (validate === "edit" && type?.length === 0 && value.length === 0) {
        //console.log("in the editValidation01");

        data = {
          type: validationType,
          bool: false,
          message: "*Please attach at least one reciept",
        };
      } else if (validate !== "edit" && value.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please attach at least one reciept",
        };
      } else if (uploadFileNameSpecialCharacterValidation(value) === true) {
        data = {
          type: validationType,
          bool: false,
          message:
            "*Please enter the valid file name (no special characters allowed other than (,),-,_ )",
        };
      } else if (uploadFileNameCharLengthValidation(value) === true) {
        data = {
          type: validationType,
          bool: false,
          message:
            "*Please enter the valid file name (file name cannot be greater than 100 characters)",
        };
      } else if (dragDropFileValidationFileType(value) === true) {
        data = {
          type: validationType,
          bool: false,
          message: "*Only JPEG, JPG, XLS, XLSX, PNG and PDF allowed",
        };
      } else if (dragDropFileValidationUniqueFiles(value) === true) {
        data = {
          type: validationType,
          bool: false,
          message: "*Duplicate files are not allowed",
        };
      } else if (editDragDropFileValidationUniqueFiles(type, value) === true) {
        data = {
          type: validationType,
          bool: false,
          message: "*Duplicate files are not allowed",
        };
      } else if (
        validate != "edit" &&
        dragDropFileValidationTotalSize(value) === true
      ) {
        data = {
          type: validationType,
          bool: false,
          message:
            "*Total size of attached reciepts has exceeded 10 mb. Please remove some files, or add compressed files.",
        };
      } else if (
        validate == "edit" &&
        editDragDropFileValidationTotalSize(type, value) === true
      ) {
        //console.log(" type?.length editValidation04 ", type);
        //console.log(" value.length editValidation05", value);
        data = {
          type: validationType,
          bool: false,
          message:
            "*Total size of attached reciepts has exceeded 10 mb. Please remove some files, or add compressed files.",
        };
      } else {
        //console.log("Value:===========", value);
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "Project And Split Box":
      // console.log("findIndex validation: ", value);
      if (value.findIndex((item) => item.projectName.projectID === "") !== -1) {
        //value.findIndex((item) => item.projectName.projectID === ""
        data = {
          type: validationType,
          bool: false,
          message: "*Please select the Project",
        };
      } else if (
        value.findIndex(
          (item) => item.splitAmount === "" || item.splitAmount == 0
        ) !== -1
      ) {
        // //console.log(
        //   "findIndex: ",
        //   value.findIndex((item) => item.projectName.projectID === "")
        // );
        data = {
          type: validationType,
          bool: false,
          message:
            "*Split amount of one of the projects is 0. Please enter split amount.",
        };
      } else if (negativeValidation(value) === true) {
        data = {
          type: validationType,
          bool: false,
          message:
            "*You have entered negative value. Please enter a positive value. ",
        };
      } else if (projectAndSplitBoxValidation(value, type) === true) {
        data = {
          type: validationType,
          bool: false,
          message:
            "*Total of split amount should be equal to the claimed amount",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "Currency":
      if (value.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter Currency",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "Bill Amount":
      if (value.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter the Bill Amount",
        };
      } else if (negativeValidation(value) === true) {
        data = {
          type: validationType,
          bool: false,
          message:
            "*You have entered negative value. Please enter a positive value. ",
        };
      } else if (
        value.toString().split(".")[1] &&
        value.toString().split(".")[1].length > 2
      ) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter Bill Amount upto Two decimal ",
        };
      } else if (value.length > 10) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter Bill Amount upto Ten digit include (.) ",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "Claim Amount":
      // //console.log(
      //   "validateAttendeesList:",
      //   validate,
      //   "expenseCategory:",
      //   validate2 ? validate2 : ""
      // );
      //console.log("value:", value, "type:", type);
      //console.log(Number(value) > Number(type));
      if (Number(value) == 0 || value == "") {
        //console.log("1");
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter the Claim Amount",
        };
      } else if (negativeValidation(value) === true) {
        //console.log("2");
        data = {
          type: validationType,
          bool: false,
          message:
            "*You have entered negative value. Please enter a positive value. ",
        };
      } else if (
        value.toString().split(".")[1] &&
        value.toString().split(".")[1].length > 2
      ) {
        //console.log("3");
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter Claim Amount upto Two decimal ",
        };
      } else if (value.length > 10) {
        //console.log("4");
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter Claim Amount upto Ten digit (.)",
        };
      } else if (Number(value) > Number(type)) {
        //console.log("5" , Number(value) , Number(type),Number(value) > Number(type));
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter the smaller Claim Amount than Bill Amount  ",
        };
      } else if (validate2?.policyApplicable == "N") {
        data = { type: validationType, bool: true, message: "" };
      } else if (
        Number(validate2?.exceptionalApproval) == 1 &&
        value !== "" &&
        validate2?.policyApplicable == "Y" &&
        Number(value) > Number(validate2?.policyLimit)
      ) {
        // console.log("6, value", value);
        //console.log(
        // "validate2989898?.exceptionalApproval",
        // validate2?.exceptionalApproval
        // );
        data = {
          type: validationType,
          bool: true,
          message:
            "This expense requires exceptional approval. It will go to PM for approval.",
        };
      } else if (validate?.length === 1 && validate2?.policyApplicable == "Y") {
        //console.log("7771");
        // //console.log(
        //   "validate2?.exceptionalApproval 7772",
        //   validate2?.exceptionalApproval
        // );
        // //console.log(
        //   "validate2?.exceptionalApproval 7772",
        //   validate ? validate : ""
        // );
        if (noOfMonths == null || noOfMonths == "" || noOfMonths == 0) {
          if (
            Number(value) > Number(validate[0].remainingAmount) &&
            validate[0].categoryId != 11
          ) {
            console.log("77731", validate[0].remainingAmount, noOfMonths);
            data = {
              type: validationType,
              bool: false,
              message:
                "*Claim amount entered is more than remaining policy amount.",
            };
          } else {
            //console.log("9");
            data = { type: validationType, bool: true, message: "" };
          }
        } else {
          if (
            Number(value) > Number(validate[0].remainingAmount * noOfMonths) &&
            validate[0].categoryId != 11
          ) {
            //console.log("77732", validate[0].remainingAmount, noOfMonths);
            data = {
              type: validationType,
              bool: false,
              message:
                "*Claim amount entered is more than remaining policy amount.",
            };
          } else {
            //console.log("77733");
            data = { type: validationType, bool: true, message: "" };
          }
        }
      } else {
        //console.log("77734");
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "Payment":
      if (value.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please select the Payment Method",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;

    case "Attendees":
      // console.log(
      //   "attendeesValidation selectedProject value",
      //   value,
      //   type,
      //   validate
      // );
      if (value?.length === 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please Select the Attendees",
        };
      } else if (value?.length > 0) {
        // console.log("attendeesValidation value of Attendees", value);
        // console.log("attendeesValidation type of Attendees", type);
        // console.log("attendeesValidation validate of Attendees", validate);
        const validAttendees = handleAttendees(value, type, validate);
        //console.log("attendeesValidation validAttendees", validAttendees);
        if (validAttendees?.length > 0) {
          data = {
            type: validationType,
            bool: true,
            message: validAttendees,
          };
        } else {
          data = { type: validationType, bool: true, message: "" };
        }
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
    case "Declaration CheckBox":
      if (value === false) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please accept the declaration.",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
    case "From Date":
      //console.log("validation From Date", value);
      if (value == null || value == "") {
        data = {
          type: validationType,
          bool: false,
          message: "*Please select From Date",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
    case "To Date":
      if (value == null || value == "") {
        data = {
          type: validationType,
          bool: false,
          message: "*Please select To Date",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
    case "For Month":
      if (value == null || value == "") {
        data = {
          type: validationType,
          bool: false,
          message: "*Please select the month of expense",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
    case "From Location":
      if (value == null || value == "") {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter From Location",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
    case "To Location":
      if (value == null || value == "") {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter To Location",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
    case "Distance In Between":
      if (value == null || value == 0) {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter Distance",
        };
      } else if (negativeValidation(value) === true) {
        data = {
          type: validationType,
          bool: false,
          message:
            "*You have entered negative value. Please enter a positive value. ",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
    case "Quantity":
      if (value === null || value === 0 || value === "") {
        data = {
          type: validationType,
          bool: false,
          message: "*Please enter valid quantity",
        };
      } else {
        data = { type: validationType, bool: true, message: "" };
      }
      break;
  }
  return data;
};